<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('sale_create')"
                variant="primary"
                :to="{ name: 'add-sale-campaign' }"
              >
                <span class="text-nowrap">Add Campaign</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refSalesTable"
          class="position-relative"
          :items="fetchSales"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          borderless
        >
          <!-- Column: Title -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'detail-campaign', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
            </b-media>
          </template>

          <!-- Column: Type -->
          <template #cell(type)="data">
            <div class="text-nowrap">
              {{ data.item.type }}
            </div>
          </template>

          <!-- Column: Region -->
          <template #cell(region)="data">
            <div class="text-nowrap">
              {{ data.item.region }}
            </div>
          </template>

          <!-- Column: Sale Configs -->
          <template #cell(saleConfig)="data">
            <div class="text-nowrap">
              <div v-for="(bulk, index) in data.item.bulkConfigs" :key="index">
                Amount: {{ bulk.amount }} - Percent: {{ bulk.percent }}%
              </div>
              <div v-if="data.item.batchDiscountPercent != null">
                Percent: {{ data.item.batchDiscountPercent }}%
              </div>
              <div v-if="data.item.fixPriceDiscount != null">
                Sale Price: {{ data.item.fixPriceDiscount }}
              </div>
            </div>
          </template>

          <!-- Column: Created At -->
          <template #cell(createdAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>

          <!-- Column: Updated At -->
          <template #cell(updatedAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.updatedAt) }}
            </span>
          </template>

          <!-- Column: Start Time -->
          <template #cell(startTime)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.startTime) }}
            </span>
          </template>

          <!-- Column: End Time -->
          <template #cell(endTime)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.endTime) }}
            </span>
          </template>

          <!-- Column: Enable -->
          <template #cell(enable)="data">
            <div>
              <b-form-checkbox
                v-model="data.item.isEndable"
                class="custom-control-success"
                name="check-button"
                switch
                :disabled="!$can('sale_deactivate') || isExpired(data.item.endTime)"
                @change="switchEnableSale(data.item)"
              />
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('sale_update')"
              :to="{ name: 'detail-campaign', params: { id: data.item.id } }"
            >
              Edit
            </b-link>
            <b-link
              @click="showModalTracking(data.item.id)"
              v-if="$can('tracking_read')"
            >
              Tracking
            </b-link>
            <b-link @click="deleteSale(data.item.id)" v-if="$can('sale_delete')">Delete</b-link>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSales"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <tracking-status
      :is-show-modal-tracking.sync="isShowModalTracking"
      :target="target"
      :actor="actor"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BCardText,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
import store from "@/store";
// eslint-disable-next-line import/no-cycle
import { ref } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
// eslint-disable-next-line import/no-cycle
import useSalesList from "@/views/saleCampaign/useSalesList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TrackingStatus from "../modal/TrackingStatus.vue";
import { isExpired } from "@/utils/utils";

export default {
  components: {
    BOverlay,
    TrackingStatus,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    vSelect,
    BCardText,
    BFormCheckbox,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false);
    const target = ref("");
    const actor = ref("");
    const isShowModalTracking = ref(false);
    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      timeout,
      showTable,
      fetchSales,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSalesTable,
      refetchData,
      totalSales,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useSalesList();

    return {
      timeout,
      showTable,
      target,
      actor,
      isShowModalTracking,
      // Sidebar
      isAddNewUserSidebarActive,
      fetchSales,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSalesTable,
      refetchData,
      totalSales,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      isExpired,
    };
  },
  methods: {
    showModalTracking(id) {
      this.target = `storeConnection:sale:${id}`;
      this.actor = "";
      this.isShowModalTracking = true;
    },
    formatDate(time) {
      return moment(time).format("HH:mm:ss DD/MM/YYYY");
    },
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 600);
    },
    switchEnableSale(switchSale) {
      const payload = {};
      // eslint-disable-next-line prefer-destructuring
      const id = switchSale.id;
      payload.isEndable = switchSale.isEndable;
      payload.productIds = switchSale.productIds;
      store
        .dispatch("sale/updateSale", { id, sale: payload })
        .then(() => {
          this.refreshTable();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update sale success",
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
        })
        .catch((e) => {
          this.refreshTable();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    deleteSale(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete this sale campaign.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            store
              .dispatch("sale/deleteSale", { id })
              .then(() => this.refreshTable())
              .catch((e) => {
                throw new Error(e.response.data.message);
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
